<script setup>
import AuthenticatedLayout from "@/Layouts/AuthenticatedLayout.vue";
import FloatLabel from "primevue/floatlabel";
import InputText from "primevue/inputtext";
import InputNumber from 'primevue/inputnumber';
import DatePicker from "primevue/datepicker";
import Select from 'primevue/select';
import ToggleSwitch from 'primevue/toggleswitch';

import {onMounted, ref} from "vue";
import {HTTP} from "@/Plugins/axios";
import { useRouter } from "vue-router";

const API_URL = process.env.VUE_APP_API_URL;
const router = useRouter();

const moneySections = ref();
const credits = ref();
const categories = ref();
const subcategories = ref();
const periods = ref([
  {name: 'Day', value: 'day'},
  {name: 'Week', value: 'week'},
  {name: 'Month', value: 'month'},
  {name: 'Year', value: 'year'},
])

const form = ref({
  name: "",
  quantity: null,
  date: new Date().toISOString().split('T')[0],
  section_id: "",
  needed: false,
  useCredit: false,
  credit_id: null,
  months: null,
  interest: 0,
  is_recurring_expense: false,
  every: null,
  category_id: null,
  subcategory_id: null,
  processing: false
});

onMounted(() => {
  // Load moneySections
  getMoneySections()
  // Load credits
  getCredits()
  getCategories()
})

const getMoneySections = () => {
  HTTP({
    url: `${API_URL}get/sections`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        moneySections.value = response.data.data;
        form.value.section_id = response.data.data[0]['id']
      })
      .catch((err) => {})
}

const getCredits = () => {
  HTTP({
    url: `${API_URL}credits`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        if (response.data.data.length > 0)
          credits.value = response.data.data;
      })
      .catch((err) => {})
}

const getCategories = () => {
  HTTP({
    url: `${API_URL}categories`,
    method: 'GET',
    data: {
    }
  })
      .then((response) => {
        categories.value = response.data.data;
      })
      .catch((err) => {})
}

const submit = (form) => {
  form.processing = true;
  // Send api request to create expense
  HTTP({
    url: `${API_URL}expenses`,
    method: 'POST',
    data: {
      name: form.name,
      quantity: form.quantity,
      date: form.date,
      section_id: form.section_id,
      needed: form.needed,
      useCredit: form.useCredit,
      credit_id: form.credit_id,
      months: form.months,
      interest: form.interest,
      is_recurring_expense: form.is_recurring_expense,
      every: form.every,
      category_id: form.category_id,
      subcategory_id: form.subcategory_id,
    }
  })
      .then((response) => {
        form.processing = false
        router.push('/dashboard');
      })
      .catch((err) => {
        form.processing = false
      })
}

const validateSubmit = (form) => {
  if (!form.name || !form.quantity || !form.category_id || (!form.section_id && !form.useCredit))
    return true;

  if (form.useCredit && !form.credit_id)
    return true;

  if (form.is_recurring_expense && !form.every)
    return true;

  if (form.processing)
    return true;
}

const cancel = () => {
  router.push('/dashboard');
}
</script>

<template>
  <AuthenticatedLayout>
    <div class="bg-finexpal-gray-100 w-full px-8">
      <h1 class="text-3xl mt-3 mb-8">Create expense</h1>
      <div class="flex flex-column h-[90%]">
        <form class="w-full">
          <FloatLabel class="w-full">
            <InputText id="name" v-model="form.name" class="w-full"/>
            <label for="name">Name</label>
          </FloatLabel>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
<!--              <InputText id="quantity" v-model="form.quantity" class="w-full"/>-->
              <InputNumber v-model="form.quantity" inputId="quantity" mode="currency" currency="USD" locale="en-US" fluid class="w-full"/>
              <label for="quantity">Quantity</label>
            </FloatLabel>
            <!--Date Selector-->
            <FloatLabel class="w-2/5">
              <DatePicker v-model="form.date" inputId="date" showIcon fluid :showOnFocus="false" dateFormat="yy-mm-dd" class="w-full"/>
              <label for="date">Date (Y-m-d)</label>
            </FloatLabel>
          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <Select inputId="category_id" v-model="form.category_id" :options="categories" filter optionLabel="name" optionValue="id" placeholder="Select a category" class="w-full" />
              <label for="category_id">Category</label>
            </FloatLabel>
            <FloatLabel class="w-2/5">
              <Select inputId="subcategory_id" v-model="form.subcategory_id" :options="subcategories" filter optionLabel="name" optionValue="id" placeholder="Select a subcategory" class="w-full"/>
              <label for="subcategory_id">Subcategory</label>
            </FloatLabel>

          </div>
          <div class="card flex justify-between items-center w-full pt-8">
            <FloatLabel class="w-2/5">
              <Select v-model="form.section_id" :options="moneySections" optionLabel="name" optionValue="id" placeholder="Select a money section" class="w-full" :disabled="form.useCredit" />
              <label for="quantity">Money Section</label>
            </FloatLabel>

          </div>
          <div class="card flex items-center w-2/5 pt-8">
            <div class="flex items-center">
              <label for="needed">Needed</label>
              <ToggleSwitch inputId="needed" v-model="form.needed" class="ml-4"/>
            </div>
          </div>
          <div class="card flex items-center justify-between w-full pt-8">
            <div class="flex w-2/5 items-center">
              <label for="recurring">Is recurrent expense</label>
              <ToggleSwitch inputId="recurring" v-model="form.is_recurring_expense" class="ml-7"/>
            </div>
            <div v-if="form.is_recurring_expense" class="flex w-2/5 items-center">
              <FloatLabel class="w-full">
                <Select inputId="every" v-model="form.every" :options="periods" optionLabel="name" optionValue="value" placeholder="Select the period" class="w-full" />
                <label for="every">Period</label>
              </FloatLabel>
            </div>
          </div>
          <div class="card flex items-center justify-between w-full pt-8">
            <div class="flex w-1/5 items-center">
              <label for="recurring">Use credit</label>
              <ToggleSwitch inputId="recurring" v-model="form.useCredit" class="ml-7"/>
            </div>
            <div v-if="form.useCredit" class="flex w-1/5 items-center">
              <FloatLabel class="w-full">
                <Select inputId="credit" v-model="form.credit_id" :options="credits" optionLabel="name" optionValue="id" placeholder="Select the credit" class="w-full" />
                <label for="every">Credit</label>
              </FloatLabel>
            </div>
            <div v-if="form.useCredit" class="flex w-1/5 items-center">
              <FloatLabel class="w-full">
                <InputNumber v-model="form.months" inputId="minmax" :min="0" :max="100" fluid class="w-full"/>
                <label for="every">Months</label>
              </FloatLabel>
            </div>
            <div v-if="form.useCredit" class="flex w-1/5 items-center">
              <FloatLabel class="w-full">
                <InputNumber v-model="form.interest" inputId="minmax" :min="0" :max="100" :minFractionDigits="2" fluid class="w-full"/>
                <label for="every">Interest</label>
              </FloatLabel>
            </div>
          </div>
          <div class="flex w-full justify-end mt-10 mb-4">
            <button class="rounded-md w-1/5 h-10 text-white" :class="[validateSubmit(form) ? 'bg-finexpal-gray-200': 'bg-blue-700']" :disabled="validateSubmit(form)" @click.prevent="submit(form)">Create</button>
            <button class="ml-6 rounded-md w-1/5 h-10 border border-solid border-black" @click.prevent="cancel">Cancel</button>
          </div>
        </form>
      </div>
    </div>
  </AuthenticatedLayout>
</template>

<style scoped>

</style>